import { Alert, Box, Container, Grid, Typography, Button } from '@mui/material'
import { ReactEventHandler, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PreferenceCenterContext } from '../../contexts/PreferenceCenter'
import { Translations } from '../../translations'
import { SectionTitle } from '../reusable/SectionTitle'

export function OptInSettings({ handleListUnsub }: { handleListUnsub: ReactEventHandler }): JSX.Element {
    const context = useContext(PreferenceCenterContext)
    const { t: translate } = useTranslation()

    function handleUnsubscribe(event: any) {
        handleListUnsub(event)
    }

    return <>
        <Container sx={{ marginTop: 5 }}>
            <SectionTitle
                title={translate(Translations.optin_title)}
                description={translate(Translations.optin_description, { companyName: context?.account.name })}
            />
            <Grid container spacing={2}>
                <Grid item xs={10} display='flex' alignItems={'center'}>
                    <Box>
                        <Typography fontWeight={600}>{(context?.listUnsubd || context?.globalUnsubd) ? translate(Translations.subscribe) : translate(Translations.unsubscribe)}</Typography>
                        <Typography fontWeight={100}>{(context?.listUnsubd || context?.globalUnsubd) ? translate(Translations.subscribe_from_list) : translate(Translations.unsubscribe_from_list)}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} display='flex' flexDirection={'row-reverse'}>
                    <Button
                        disabled={context?.globalUnsubd == true}
                        onClick={handleUnsubscribe}
                        variant={
                            (context?.listUnsubd || context?.globalUnsubd)
                                ? "contained"
                                : "outlined"
                        }
                        style={{
                            border: '2px solid',
                            fontSize: '50%',
                            padding: '8px 12px 8px 12px',
                        }}
                    >
                        {(context?.listUnsubd || context?.globalUnsubd)
                            ? translate(Translations.subscribe)
                            : translate(Translations.unsubscribe)}
                    </Button>
                </Grid>
                {context?.globalUnsubd &&
                    <Grid item xs={12} display='flex' alignItems={'center'}>
                        <Box paddingTop={4}>
                            <Alert color='warning'>
                                <Typography fontWeight={600}>{translate(Translations.unsubscribe_from_all_lists, { companyName: context.account.name })}</Typography>
                            </Alert>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Container>
    </>
}
